import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Large Global Enterprise"
const pageTitle = "Large Global Enterprise"
const subTitle = "Cloud Infrastructure "
const para =
"Creation of Vault topology that had Disaster recovery “DR” and Performance Replication “PR” enabled.  Integrate Continuous Integration and Continuous Deployment “CI/CD” with Vault for storing secrets management system (HashiCorp Vault) Enabling log rotation capabilities In Vault Implemented TFE for IAC on multiple cloud providers (Azure, AWS) Migration of secrets from AWS Secret manager to Vault Adoption of new features Integration with other IAM tools (Okta, SailPoint etc.)."
const blockquote = `"HashiCorp Terraform Enterprise & Vault
Enterprise."`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
      <p>The client needed an Enterprise ready
FIPS compliant Secrets management
tool for cloud and on-premise assets.</p>
      </>
    ),
  },
  {
    id: 2,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
        Vault Enterprise was selected for its wide support of various Secrets engines HSM integration, namespaces and performance and DR replication.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process`,
    listItems: (
      <>
        <p>
        Vault was implemented using the banks CICD tool set (Ansible, Git, Jira) and integrated with their life cycle management. 

        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `Result`,
    listItems: (
      <>
        <p>
          Vault was successfully deployed in production and is in use today in
          NAM.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query FifthQuery {
    casedetailsJson(slug: { eq: "large-global-enterprise" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
